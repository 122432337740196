import $ from 'jquery';
import './modules/common';
import {initComponentsInContainer} from "./modules/components";

import * as slider from './modules/slider';
slider.init();

import * as teaserSlider from './modules/teaserSlider';
teaserSlider.init();

import * as hotspot from './modules/hotspot';
hotspot.init();

import * as thumbSlider from './modules/thumbSlider';
thumbSlider.init();

import * as lightbox from './modules/lightbox';
lightbox.init();

import * as technogramm from './modules/technogramm';
technogramm.init();

import * as parsley from './modules/parsley';
parsley.init();

import * as scrollSpy from './modules/scrollSpy';
scrollSpy.init();

import * as scrollTo from './modules/scrollTo';
scrollTo.init();

import * as multiDropdown from './modules/multiDropdown';
multiDropdown.init();

import * as filterForm from './modules/filter-form/filter-form';
filterForm.init();

import * as tooltip from './modules/tooltip';
tooltip.init();

import * as compareList from './modules/compareList';
compareList.init();

import * as productGrid from './modules/product-grid';
productGrid.init();

import * as downloadUrlChange from './modules/downloadUrlChange';
downloadUrlChange.init();

import * as expandable from './modules/expandable-block';
expandable.init();

import * as hoverTabs from './modules/hoverTabs';
hoverTabs.init();

initComponentsInContainer($('body'));