import {addComponentInitializer} from "./components";
import $ from 'jquery';
import * as lightbox from "./lightbox";

export function init () {
    let sliderId:number = 0;

    addComponentInitializer(function ($scope:JQuery) {
        let $thumbSlider = $scope.find('.js-thumb-slider');

        import('slick-carousel').then(function () {
            $thumbSlider.each(function () {
                let $container = $(this);
                let $mainSlider = $container.find('.js-thumb-slider__main');
                let $thumbSlider = $container.find('.js-thumb-slider__thumbs');
                let id:number = sliderId++;

                $mainSlider.attr('id', 'main-slider__main-' + id);
                $thumbSlider.attr('id', 'thumb-slider__thumb-' + id);

                $mainSlider.slick({
                    nextArrow: '<button type="button" class="slick-next btn-no-styling d-md-none" aria-label="Next"><span class="icon icon-arrow-right"></span></button>',
                    prevArrow: '<button type="button" class="slick-prev btn-no-styling d-md-none" aria-label="Previous"><span class="icon icon-arrow-left"></span></button>',
                    asNavFor: matchMedia('(min-width: 768px)').matches ? '#thumb-slider__thumb-' + id : '',
                    dots: false
                });

                lightbox.init();

                if (matchMedia('(min-width: 768px)').matches) {
                    if ($thumbSlider && $thumbSlider.length) {
                        $thumbSlider.slick({
                            dots: false,
                            slidesToShow: 3,
                            swipeToSlide: true,
                            asNavFor: '#main-slider__main-' + id,
                            focusOnSelect: true,
                            vertical: true,
                            verticalSwiping: true,
                            nextArrow: '<button type="button" class="slick-next btn-no-styling" aria-label="Next"><span class="icon icon-hexagon"></span></button>',
                            prevArrow: '<button type="button" class="slick-prev btn-no-styling" aria-label="Previous"><span class="icon icon-hexagon"></span></button>'
                        });
                    }
                }
            });
        });
    });
}