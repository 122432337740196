"use strict";

import $ from 'jquery';

let defaultFilterState = [];
let state = [];
let subscribers = [];

$(window).on('popstate', function (event) {
    state = defaultFilterState;

    if (event.originalEvent.state) {
        state = event.originalEvent.state.filter;
    }

    notify();
});

function notify() {
    $.each(subscribers, function (index, callback) {
        callback();
    });
}

function getUrl() {
    let baseUrl = location.origin + location.pathname;
    let url = baseUrl;

    let activeFilter = $.grep(state, function (filter) {
        if (filter.isHidden) {
            return false;
        }

        if (filter.isCheckboxFilter) {
            return filter.isActive;
        } else {
            return filter.value;
        }
    });

    let params = $.map(activeFilter, function (filter) {
        return filter.name + '=' + filter.value;
    });

    url += params.length ? ((baseUrl.indexOf('?') ? '?' : '&') + params.join('&')) : '';

    return url;
}

export function getFilter(filtername:string, value?:string) {
    return $.grep(state, function (filter) {
        if (typeof(value) === 'undefined') {
            return filtername === filter.name;
        } else {
            return filtername === filter.name && filter.value === value;
        }
    })[0];
}

function remove(filterToRemove:string) {
    if (!filterToRemove) {
        return;
    }

    state = $.grep(state, function (filter) {
        if (filter.isArray) {
            return filter.name !== filterToRemove.name || filter.value !== filterToRemove.value;
        } else {
            return filter.name !== filterToRemove.name;
        }
    });
}

function set(inputs) {
    $.each(inputs, function (index, newInput) {
        let existingFilter;
        if (newInput.isArray) {
            existingFilter = getFilter(newInput.name, newInput.value);
        } else {
            existingFilter = getFilter(newInput.name);
        }

        if (existingFilter) {
            removeFilter(existingFilter);
        }

        state.push(newInput);
    });
}

export function setInputs(inputs) {
    // always reset page param when inputs are set
    remove(getFilter('page'));

    set(inputs);

    let url = getUrl();
    history.pushState({'filter': state}, "title", url);

    notify();
}

export function setDefaultInputs(inputs) {
    set(inputs);

    $.each(inputs, function (index, newInput) {
        defaultFilterState.push(newInput);
    });
}

export function removeFilter(filter) {
    remove(filter);

    let url = getUrl();
    history.pushState({'filter': state}, "title", url);

    notify();
}

export function getState() {
    /*copy to prevent state changes*/
    return $.extend([], state);
}

export function subscribe(callback) {
    subscribers.push(callback);
}

export function getSerializedParams() {
    let activeFilter = $.grep(state, function (filter) {
        if (filter.isCheckboxFilter) {
            return filter.isActive;
        } else {
            return filter.value;
        }
    });

    return $.map(activeFilter, function (filter) {
        return {
            name: filter.name,
            value: filter.value
        };
    });
}
