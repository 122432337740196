import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        $scope.find('.js-expandable').each(function () {
            let expandableElement = $(this);
            let expandableContent = expandableElement.find('.js-expandable__content');
            if (expandableContent[0].scrollHeight > expandableContent.height() + 50) {
                expandableElement.addClass('is-expandable');

                expandableElement.find('.js-expandable__more').on('click', function (evt) {
                    evt.preventDefault();
                    expandableElement.toggleClass('is-collapsed');
                });
            } else {
                expandableElement.addClass('is-not-expandable');
            }
        });

    });
}