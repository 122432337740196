"use strict";

import $ from 'jquery';
import * as filterStore from './filter-store';
import {addComponentInitializer, initComponentsInContainer} from "../components";

let $filterFormResultElements = $('');
let $filterFormLoading = $('');
let baseUrl = _config.filterStoreUrl;
let pendingRequest: any;

export function init () {
    $('.js-filter-form').each(function (index, form) {
        let $form = $(form);
        filterStore.setDefaultInputs(getInputStates($form));
    });

    filterStore.subscribe(function () {
        fetchResults();
    });

    let $pagination = $('.js-filter-form__paging');
    if ($pagination && $pagination.length) {
        filterStore.setDefaultInputs([{
            name: 'page',
            value: $pagination.find('.active > a').data('page')
        }]);
    }

    addComponentInitializer(function ($container:JQuery) {
        $container.find('.js-filter-form').each(function (index, form) {
            let $form = $(form);

            $form.on('submit', function (evt) {
                let $form = $(this);

                evt.preventDefault();
                filterStore.setInputs(getInputStates($form));
            });

            if($form.data('trigger')) {
                $form.on($form.data('trigger'), function () {
                    filterStore.setInputs(getInputStates($form));
                });
            }

            filterStore.subscribe(function () {
                setFilterState($form);
            });
        });

        let $newfilterFormResultElements = $container.find('.js-filter-form__result');
        $filterFormResultElements = $filterFormResultElements.add($newfilterFormResultElements);
        $filterFormLoading = $filterFormLoading.add($container.find('.js-filter-form__loading'));

        $container.find('.js-filter-form__paging').on('click', '[data-page]', function (evt) {
            evt.preventDefault();
            filterStore.setInputs([{
                name: 'page',
                value: $(this).data('page')
            }]);
        });


        $container.find('.js-filter-form__link').on('click', function (evt) {
            evt.preventDefault();
            filterStore.setInputs([{
                name: $(this).data('filter'),
                value: $(this).attr('href')
            }]);
        });
    });
}

interface InputStateType {
    name: string,
    label: string,
    value: string,
    isActive?: boolean,
    isCheckboxFilter?: boolean,
    isArray?: boolean,
    isFilter?: boolean,
    isHidden?: boolean
}

function getInputStates ($form:JQuery) {
    let inputsState: Array<InputStateType> = [];
    $form.find('.js-filter-form__input').each(function (index, input) {
        let newInputState:InputStateType = {
            name: $(input).attr('name') || '',
            label: $(input).data('label') || '',
            value: $(input).val() || ''
        };

        if ($(input).is('[type=checkbox]')) {
            newInputState.isActive = $(input).is(':checked');
            newInputState.isCheckboxFilter = true;
        }

        if ($(input).attr('name').indexOf('[]') !== -1) {
            newInputState.isArray = true;
        }

        newInputState.isFilter = !$(input).hasClass('js-filter-form__input--no-filter');
        newInputState.isHidden = $(input).hasClass('js-filter-form__input--hidden-in-url');

        inputsState.push(newInputState);
    });

    return inputsState;
}

function setFilterState($form:JQuery) {
    $form.find('.js-filter-form__input').each(function (index, input) {
        let $input = $(input);
        let filter;

        if ($input.is('[type=checkbox], [type=radio]')) {
            filter = filterStore.getFilter($input.attr('name'), $input.val());

            let $collapseParent = $input.closest('.collapse:not(.in)');

            if (filter && filter.isActive) {
                if (!$input.prop('checked')) {
                    $input.prop('checked', true);

                    if ($collapseParent && $collapseParent.length) {
                        $collapseParent.collapse('show');
                    }
                }
            } else {
                if ($input.prop('checked')) {
                    $input.prop('checked', false);
                }
            }
        } else {
            /*none radio & checkbox inputs*/
            filter = filterStore.getFilter(input.name);

            if (filter) {
                $input.val(filter.value);
            } else {
                $input.val(null);
            }
        }

        $input.trigger('value-change');
    });
}
interface CacheEntry {
    url: string,
    content: string
}
let cache: Array<CacheEntry> = [];
function fetchResults() {
    if (pendingRequest) {
        pendingRequest.abort();
    }

    let url = baseUrl + (baseUrl.indexOf('?') < 0 ? '?': '&') + $.param(filterStore.getSerializedParams());

    let cacheResult = $.grep(cache, function (cacheEntry) { return cacheEntry.url === url});

    if (cacheResult.length) {
        setResults(cacheResult[0].content);
    } else {
        $filterFormResultElements.addClass('is-loading');
        $filterFormLoading.attr('hidden', false).removeClass('hidden');

        pendingRequest = $.ajax(url, {
            cache: false,
            dataType : 'html',
        }).done(function (data, textStatus, jqXHR) {
            pendingRequest = null;

            setResults(data);

            cache.push({
                url: url,
                content: data
            });

            if (cache.length > 40) {
                cache.shift();
            }
        });
    }
}

function setResults(result:string){
    $filterFormResultElements.removeClass('is-loading');
    $filterFormLoading.attr('hidden', true).addClass('hidden');
    $filterFormResultElements.empty();
    let $newContent = $(result);
    $filterFormResultElements.append($newContent);
    initComponentsInContainer($newContent);
}