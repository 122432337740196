import $ from 'jquery';

export function init () {
    let $console = $('.js-console'),
        $stopContainer = $('.main-footer'),
        lastScrollTop: number = 0;

    if($(window).scrollTop() > $console.data('offset')) {
        $console.addClass('is-affix');
    }

    $(window).scroll(function () {
        let scrollTopPosition = $(window).scrollTop() || 0;

        if (lastScrollTop > scrollTopPosition) {
            /*scroll up*/
            if ($(window).scrollTop() < $console.data('offset')) {
                $console.removeClass('is-affix');
            }

            if($stopContainer.offset().top >= $(window).scrollTop() + $(window).innerHeight() && $console.hasClass('is-affix-bottom')) {
                $console.removeClass('is-affix-bottom').css('bottom', 0);
            }
        } else if (lastScrollTop < scrollTopPosition) {
            /*scroll down*/
            if($(window).scrollTop() > $console.data('offset')) {
                $console.addClass('is-affix');
            }

            if($stopContainer.offset().top <= $console.offset().top + $console.outerHeight()) {
                $console.addClass('is-affix-bottom').css('bottom', $stopContainer.outerHeight());
            }
        }

        lastScrollTop = scrollTopPosition;
    });
}