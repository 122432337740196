import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        let $tab = $scope.find('.js-hover-tab');

        $tab.on('mouseover', function () {
            //needed for mouseover
            $tab.each(function () {
               $($(this).attr('href')).removeClass('active show');
            });

            $(this).tab('show');
        });
    })
}