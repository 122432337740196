import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        let $scrollspy = $scope.find('.js-scrollspy-nav');

        if(!$scrollspy.length) {
            return;
        }

        let offset:number = $scrollspy.position().top,
            id:number = 0;

        //add nav items
        function addNavItem (text:string, anchorId:string, count:number) {
            $scrollspy.append('<li class="nav-item">' +
                '<a class="nav-link scrollspy__item js-scroll-to" href="#' + anchorId + '">' + count + '' +
                '<span class="scrollspy__item__text">' + text + '</span>' +
                '</a></li>');
        }

        $('.js-scrollspy-nav-item').each(function () {
            let $this = $(this),
                title:string = $this.data('nav-name'),
                anchorId:string = 'scrollspy-nav-item-' + id;

            $this.attr('id', 'scrollspy-nav-item-' + id);
            addNavItem(title,anchorId, id+1);
            id++;
        });

        $('body').scrollspy({
            target: '#scrollspy-nav',
            offset: offset
        });
    });
}