import {addComponentInitializer} from "./components";
import $ from 'jquery';
import loadScript from './loadScript'

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        let lightboxGroups = $scope.find('.js-lightbox-group');
        let lightboxGroupItems = $scope.find('.js-lightbox-group__item');

        import('lightgallery').then(function () {
            /* the following imports are only needed if there are videos in your lightGallery*/
            /* IMPORTANT: Do not forget to add 'lg-video-object lg-html5' to video-elements*/
            /* IMPORTANT: The controls attribute must be set on the <video>*/
            /* IMPORTANT: class "js-video-js" is not necessary for videos in the lightgallery (own molecule) */
            import('video.js').then(function (videojs) {
                /* because lg-video needs a global instance of videojs */
                window.videojs = videojs.default;
            });
            loadScript('//f.vimeocdn.com/js/froogaloop2.min.js');
            import('lg-video');

            lightboxGroups.each(function () {
                $(this).lightGallery({
                    selector: '.js-lightbox-group__item',
                    animateThumb: false,
                    download: false,
                    videojs: true,
                    counter: true,
                    prevHtml: "<span class='icon icon-arrow-left text-default'></span>",
                    nextHtml: "<span class='icon icon-arrow-right text-default'></span>"
                });
            });


            //prevent isClickable
            lightboxGroups.on('click', '.isClickable', function (evt) {
                evt.stopImmediatePropagation();
            });
        });
    });
}