import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init() {
    addComponentInitializer(function ($scope: JQuery) {
        let $dropdown = $scope.find('.js-multi-dropdown');

        $dropdown.each(function () {
            let $this = $(this),
                defaultValue = $this.data('default-value');

            $this.on('change', function () {
                let checkedCheckboxes = $this.find('input[type=checkbox]:checked').closest('.js-multi-dropdown__checkbox').find('.js-multi-dropdown__checkbox__text');

                let title = $.map(checkedCheckboxes, function (label) {
                    return $(label).text();
                }).join(', ');

                let label = $(checkedCheckboxes[0]).text(),
                    otherCount = checkedCheckboxes.length - 1;

                if (checkedCheckboxes.length > 1) {
                    label = $(checkedCheckboxes[0]).text() + ' + ' + otherCount;
                }

                if (checkedCheckboxes.length != 0) {
                    $this.children().find('.js-multi-dropdown__text').text(label);
                    $this.attr('title', title);
                } else {
                    $this.children().find('.js-multi-dropdown__text').text(defaultValue);
                    $this.attr('title', defaultValue);
                }
            });
        });

        $dropdown.find('.dropdown-menu').on('click', function (e) {
            e.stopPropagation();
        });
    });
}