import 'bootstrap';
import 'babel-polyfill';
import 'picturefill';

import * as affixNavbar from './affixNavbar';
affixNavbar.init();

import * as overlayBox from './overlayBox';
overlayBox.init();

import * as affixConsole from './affixConsole';
affixConsole.init();

import * as isClickable from './isClickable';
isClickable.init();

import * as mobileNav from './mobileNav';
mobileNav.init();

import * as touchNav from './touchNav';
touchNav.init();

import * as showCookieBar  from './cookiePermissions';
showCookieBar.showCookieBar();