import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        let $hotspots = $scope.find('.js-hotspot'),
            $hotspotToggle = $hotspots.find('.js-hotspot__toggle'),
            $hotspotDetailToggle = $hotspots.find('.js-hotspot__toggle-detail');

        $hotspotToggle.on('click', function (evt) {
            evt.preventDefault();
            let $hotspot = $(this).closest('.js-hotspot');

            if (matchMedia('(min-width: 768px)').matches) {
                if(!$hotspot.hasClass('is-open')) {
                    //close other hotspots
                    $hotspots.each(function () {
                        $(this).removeClass('is-open');
                        $('body').off('click', bodyClickHandler);
                    });

                    $hotspot.addClass('is-open');
                    $('body').on('click', bodyClickHandler);
                } else {
                    $hotspot.removeClass('is-open');
                    $('body').off('click', bodyClickHandler);
                }
            } else {
                toggleDetail($hotspot.children('.js-hotspot__toggle-detail').data('target'));
            }
        });

        $hotspotDetailToggle.on('click', function (evt) {
            evt.preventDefault();
            toggleDetail($(this).data('target'));
        });

        $('.js-hotspot-detail__close').on('click', function (evt) {
            evt.preventDefault();
            let $detail = $(this).closest('.js-hotspot-detail');

            if(!$detail.hasClass('is-open')) {
                $detail.addClass('is-open');
            } else {
                $detail.removeClass('is-open');
            }
        })
    });
}

function bodyClickHandler (evt:any) {
    if(!$(evt.target).hasClass('js-hotspot') && $(evt.target).parents('.js-hotspot').length < 1) {
        evt.preventDefault();
        $('.js-hotspot').removeClass('is-open');
        $('body').off('click', bodyClickHandler);
    }
}

function toggleDetail(detailId:string) {
    let $detail = $(detailId);

    if(!$detail.hasClass('is-open')) {
        $('.js-hotspot-detail').each(function () {
            $(this).removeClass('is-open');
        });

        $detail.addClass('is-open');
    } else {
        $detail.removeClass('is-open');
    }
}