import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init () {
    let sliderId:number = 0;

    addComponentInitializer(function ($scope:JQuery) {
        let $lists = $scope.find('.js-compare-list');

        import('slick-carousel').then(function () {
            $lists.each(function () {
                let $container = $(this);
                let slideCount = $container.data('cols');
                let $mainSlider = $container.find('.js-compare-list__slider');
                let id:number = sliderId++;

                $mainSlider.attr('id', 'main-slider__main-' + id);

                $mainSlider.slick({
                    prevArrow: $container.find('.js-compare-list--prev'),
                    nextArrow: $container.find('.js-compare-list--next'),
                    dots: false,
                    slidesToShow: slideCount,
                    infinite: false,
                    responsive: [
                        {
                            breakpoint: 767,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        },
                    ]
                });

                if($mainSlider.find('.js-compare-list-col').length > slideCount) {
                    $container.addClass('is-slider');
                }

                calcCellHeight($container);
            });
        });

        function calcCellHeight($this:any) {
            let $items = $this.find('.js-compare-list-col');

            for ( let i = 0; i < $items.length; i++ ) {
                let colheight = 0;

                $items.each(function() {
                    let currentcell = $(".compare-list-col:eq("+i+")", $(this));
                    if ( currentcell.height() > colheight ) {
                        colheight = currentcell.height();
                    }
                });

                $items.each(function () {
                    let currentcell = $(".compare-list-col:eq("+i+")", $(this));
                    currentcell.height(colheight);
                })
            }

            //todo
            expandableContent();
        }

        function expandableContent() {
            $scope.find('.js-expand-compare').each(function () {
                let expandableElement = $(this);
                let closedHeight = expandableElement.height();
                let expandableContent = expandableElement.find('.js-expand-compare__content');

                if (expandableContent[0].scrollHeight > closedHeight + 50) {
                    expandableElement.addClass('is-expandable').removeClass('is-not-expandable');

                    expandableElement.find('.js-expand-compare__more').on('click', function (evt) {
                        evt.preventDefault();
                        let t = $(this).index();

                        if(!$(this).closest('.js-expand-compare').hasClass('is-expanded')) {
                            $(this).closest('.js-compare-list').find('.is-expandable').each(function () {
                                if($(this).index() == t)
                                    $(this).addClass('is-expanded')
                            });

                            expandableElement.height(expandableContent[0].scrollHeight);
                            calcCellHeight($(this).closest('.js-compare-list'));
                        } else {

                            $(this).closest('.js-compare-list').find('.compare-list-col').each(function () {
                                if($(this).index() == t) {
                                    $(this).removeClass('is-expanded');
                                    $(this).height(closedHeight);
                                }
                            });
                        }
                    });
                } else {
                    expandableElement.addClass('is-not-expandable');
                }
            });
        }
    });
}