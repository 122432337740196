import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        let toggleOverlay = $scope.find('.js-overlay-box-toggle');

        toggleOverlay.on('click', function () {
            let $box = $($(this).data('target'));

            if(!$box.hasClass('is-visible')) {
                $box.addClass('is-visible');
                $('body').on('click', bodyClickHandler);
            } else {
                $box.removeClass('is-visible');
                $('body').off('click', bodyClickHandler);
            }
        });
    })
}

function bodyClickHandler (evt:any) {
    if(!$(evt.target).hasClass('js-overlay-box-toggle') && $(evt.target).parents('.js-overlay-box-toggle').length < 1 && $(evt.target).parents('.js-overlay-box').length < 1) {
        evt.preventDefault();
        $('.js-overlay-box').removeClass('is-visible');
        $('body').off('click', bodyClickHandler);
    }
}