import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        let $issuuOverlay = $scope.find('.js-issuu-overlay');
        let $issuuIframe = $scope.find('.js-issuu-iframe');
        let issuuUrl = 'https://e.issuu.com/embed.html?identifier=6m5j7b6zge92&embedType=script#';


        /* open */
        $scope.find('.js-open-issuu__btn').on('click', function (e){
            e.preventDefault();

            let configid = $(this).attr('data-configid');

            $issuuOverlay.addClass('is-visible');
            $issuuIframe.attr('src',issuuUrl+configid);

            console.log(issuuUrl+configid);

        });


        /* close */
        $scope.find('.js-issuu-overlay__close').on('click', function (e){
            e.preventDefault();
            let configid = $(this).attr('data-configid');

            $issuuOverlay.removeClass('is-visible');
            $issuuIframe.attr('src','');

        });
    });
}