import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        let $sliders = $scope.find('.js-teaser-slider');

        import('slick-carousel').then(function () {
            let slickOptions = {
                slidesToShow: 3,
                infinite: true,
                speed: 250,
                nextArrow: '<button type="button" class="slick-next btn-no-styling" aria-label="Next"><span class="icon icon-arrow-right"></span></button>',
                prevArrow: '<button type="button" class="slick-prev btn-no-styling" aria-label="Previous"><span class="icon icon-arrow-left"></span></button>',
                centerMode: true,
                responsive: [
                    {
                        breakpoint: 767,
                        settings: {
                            centerMode: false,
                            slidesToShow: 1
                        }
                    }
                ]
            };

            $sliders.each(function () {
                let $this = $(this),
                    options = $.extend({}, slickOptions);

                $this.slick(options);
            });
        });
    });
}