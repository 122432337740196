"use strict";

import $ from 'jquery';
import {jqXHR} from 'jquery';
window["$"] = $;
window["jQuery"] = $;


let scriptCache:{[k: string]: jqXHR} = {};
export default function loadScript (url: string) {
    if (!url.startsWith('http') && !url.startsWith('//')) {
        console.warn('\'loadScript\' should only be used for external resources. Use \'import\' for local files. \n Loaded url was \'' + url + '\'');
    }

    if (!scriptCache[url]) {
        scriptCache[url] = $.ajax({
            url: url,
            dataType: "script",
            cache: true
        });
        scriptCache[url].catch(function (errorMsg: string) {
            console.error('error loading script ' + url + '\n',  errorMsg);
        });
    }
    return scriptCache[url];
};