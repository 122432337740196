"use strict";
import $ from 'jquery';

// export function init () {
//
// }
_config.cookieServices || (_config.cookieServices = {});

let permissionsFormStorage = {};
try {
    permissionsFormStorage = JSON.parse(localStorage.getItem('cookie-permissions'));
} catch (e) {}

let servicePermissions = Object.keys(_config.cookieServices).reduce(function (servicesObject, serviceName) {
    servicesObject[serviceName] = $.extend(
        {}, {
            isAllowed: !!_config.cookieServiceDefault,
            callbacks: []
        },
        _config.cookieServices[serviceName]
    );

    if (permissionsFormStorage && typeof permissionsFormStorage[serviceName] !== 'undefined') {
        servicesObject[serviceName].isAllowed = permissionsFormStorage[serviceName];
    }

    return servicesObject;
}, {});


const PIMCORE_TARGETING = 'pimcoreTargeting';
const PIMCORE_TARGETING_COOKIE_NAME = 'pimcore_targeting_disabled';
const COOKIE_DELETE_VALUE = '; expires=Thu, 01 Jan 1970 00:00:01 GMT;';

if (servicePermissions[PIMCORE_TARGETING]) {
    if (hasCookie(PIMCORE_TARGETING_COOKIE_NAME)) {
        let cookieValue = getCookie(PIMCORE_TARGETING_COOKIE_NAME);
        servicePermissions[PIMCORE_TARGETING].isAllowed = !cookieValue;
    } else {
        document.cookie = PIMCORE_TARGETING_COOKIE_NAME + '=' + (servicePermissions[PIMCORE_TARGETING].isAllowed ? COOKIE_DELETE_VALUE : '1');
    }
}


if (_config.registerPermissionGrantedCallback) {
    _config.registerPermissionGrantedCallback.forEach(function (obj) {
        registerPermissionGrantedCallback(obj.service, obj.callback);
    });
}

export function getServicePermission(serviceName) {
    if (typeof servicePermissions[serviceName] === 'undefined') {
        console.warn('getServicePermission of undefined service "' + serviceName + '". ' +
            'Add the service to _config.cookieServices or use one of the existing ones: ',
            Object.keys(servicePermissions)
        );
    }

    return !!servicePermissions[serviceName] && !!servicePermissions[serviceName].isAllowed;
}

/* callback: passed callback is only called once */
export function registerPermissionGrantedCallback(serviceName, callback) {
    if (!serviceName || !callback) {
        console.error('registerPermissionGrantedCallback: wrong arguments serviceName:String, callback:Function');
        return;
    }

    if (getServicePermission(serviceName)) {
        callback();
    } else {
        if (servicePermissions[serviceName] && servicePermissions[serviceName].callbacks) {
            servicePermissions[serviceName].callbacks.push(callback);
        } else {
            console.warn('registerPermissionGrantedCallback of undefined service "' + serviceName + '". ' +
                'Add the service to _config.cookieServices or use one of the existing ones: ',
                Object.keys(servicePermissions)
            );
        }
    }
}

export function setServicePermission(serviceName, isAllowed) {
    servicePermissions[serviceName].isAllowed = isAllowed;
    if (isAllowed) {
        notifyCallbacks(serviceName);
    }

    try {
        let permissionsFormStorage = JSON.parse(localStorage.getItem('cookie-permissions')) || {};

        permissionsFormStorage[serviceName] = isAllowed;

        localStorage.setItem('cookie-permissions', JSON.stringify(permissionsFormStorage));
    } catch (e) {
        console.error(e);
    }

    if (serviceName === PIMCORE_TARGETING) {
        document.cookie = PIMCORE_TARGETING_COOKIE_NAME + '=' + (isAllowed ? COOKIE_DELETE_VALUE : '1');
    }
}

function notifyCallbacks(serviceName) {
    if (servicePermissions[serviceName].callbacks) {
        let currentCallback;
        while (currentCallback = servicePermissions[serviceName].callbacks.shift()) {
            currentCallback();
        }
    }
}

function acceptAll() {
    Object.keys(servicePermissions).forEach(function (serviceName) {
        setServicePermission(serviceName, true);
    });
}

function declineAll() {
    Object.keys(servicePermissions).forEach(function (serviceName) {
        setServicePermission(serviceName, false);
    });
}


/* modal */
let permissionModal;
export function showModal() {
    if (permissionModal) {
        permissionModal.remove();
    }

    permissionModal = createPermissionModal();
    notifyModalShowCallbacks();
    permissionModal.modal('show');
}

export function hideModal() {
    if (permissionModal) {
        permissionModal.modal('hide');
    }
}

function createPermissionModal() {
    let $modal = $('<div class="cookie-modal modal fade" tabindex="-1" role="dialog" aria-labelledby="permissionModal" aria-hidden="true">' +
        '  <div class="modal-dialog" role="document">' +
        '    <div class="modal-content">' +
        '      <div class="modal-header">' +
        '        <h3 class="modal-title cookie-modal__title" id="permissionModal">' + _config.cookieModal.title + '</h3>' +
        '        <button type="button" class="close" data-dismiss="modal" aria-label="Close">' +
        '          <span aria-hidden="true">&times;</span>' +
        '        </button>' +
        '      </div>' +
        '      <div class="modal-body">' +
        '        ' + createServiceOptions(servicePermissions) +
        '      </div>' +
        '      <div class="modal-footer">' +
        '        <button type="button" class="btn btn-primary js-cookie-modal__save">' + _config.cookieModal.save + '</button>' +
        '      </div>' +
        '    </div>' +
        '  </div>' +
        '</div>');


    let $globalToggle = $modal.find('input[name="all"]');
    let $serviceToggles = $modal.find('input').not($globalToggle);
    $globalToggle.on('change', function () {
        if ($(this).is(':checked')) {
            $serviceToggles.prop('checked', 'checked');
        } else {
            $serviceToggles.prop('checked', null);
        }
    });

    $serviceToggles.on('change', function () {
        $globalToggle.prop('checked', $serviceToggles.not(':checked').length ? null: 'checked');
    });

    $modal.on('click', '.js-cookie-modal__save', function () {
        hideModal();
        $serviceToggles.toArray().forEach(function (input) {
            let serviceName = input.name;
            let isAllowed = $(input).is(':checked');

            setServicePermission(serviceName, isAllowed);
            hideCookieBar();
        });
    });

    $('body').append($modal);
    return $modal;
}

function createServiceOptions(services) {
    let options = [];

    options.push('<div class="cookie-modal__item cookie-modal__item--all">' +
        createServiceOption(_config.cookieModal.toggleAll, 'all', areAllServicesAllowed(services)) +
        '</div>');

    options = options.concat(Object.keys(services).map(function (serviceName) {
        return ('<div class="cookie-modal__item">' +
            createServiceOption(services[serviceName].text, serviceName, services[serviceName].isAllowed) +
            createDetailLink(services[serviceName]) +
            '</div>'
        );
    }));

    return options.join('');
}

function createServiceOption(label, name, isChecked) {
    return (
        '<label class="cookie-modal__label">' +
        '  <span class="cookie-modal__switch">' +
        '    <input class="sr-only" name="' + name + '" type="checkbox" ' + (isChecked ? 'checked': '') + '>' +
        '    <span class="cookie-modal__switch-toggle"></span>' +
        '  </span>' +
        '  ' + label +
        '</label>'
    );
}

function areAllServicesAllowed(services) {
    return Object.keys(services).reduce(function (allAllowed, currentServiceName) {
        return !services[currentServiceName].isAllowed ? false: allAllowed;
    }, true);
}

function createDetailLink(service) {
    return (_config.cookieModal.detail && service.url)
        ? '<a class="cookie-modal__link" target="_blank" href="' + service.url + '">' + _config.cookieModal.detail + '</a>'
        : '';
}


/* cookie bar */
let $cookieBar;
function createCookieBar() {
    $cookieBar = $('<div class="cookie-bar js-cookie-bar">' +
        '    <div class="cookie-bar__text">' +
        '        ' + _config.cookieBar.text +
        '    </div>' +
        '    <div class="cookie-bar__detail">' +
        '     ' + createCookieBarDetailLink() +
        '     ' + createCookieBarSettingsLink() +
        '    </div>' +
        '    <div class="cookie-bar__buttons">' +
        '        ' + createDeclineButton() +
        '        ' + createAcceptButton() +
        '    </div>' +
        '</div>');

    $('body').append($cookieBar);

    $cookieBar.on('click', '.js-cookie-bar__accept', function () {
        acceptAll();
        hideCookieBar();
    });

    $cookieBar.on('click', '.js-cookie-bar__decline', function () {
        declineAll();
        hideCookieBar();
    });

    $cookieBar.on('click', '.js-cookie-bar__detail', function () {
        showModal();
    });

    return $cookieBar;
}

function createDeclineButton() {
    return _config.cookieBar.decline
        ? '<button type="button" class="cookie-bar__decline btn btn-no-styling js-cookie-bar__decline">' +
        '    ' + _config.cookieBar.decline +
        '</button>'
        : '';
}
function createAcceptButton() {
    return _config.cookieBar.accept
        ? '<button type="button" class="cookie-bar__accept btn btn-primary js-cookie-bar__accept">' +
        '    ' + _config.cookieBar.accept +
        '</button>'
        : '';
}

function createCookieBarDetailLink() {
    return _config.cookieBar.detail
        ?   '<a target="_blank" href="' + _config.cookieBar.detailUrl +  '" class="cookie-bar__detail-btn btn btn-no-styling">' +
        _config.cookieBar.detail +
        '</a>'
        : '';
}

function createCookieBarSettingsLink() {
    return _config.cookieBar.settings && _config.cookieServices && Object.keys(_config.cookieServices).length
        ?   '<button type="button" class="cookie-bar__detail-btn btn btn-no-styling js-cookie-bar__detail">' +
        _config.cookieBar.settings +
        '</button>'
        : '';
}

/* options.force: if set, force the cookie bar to be shown even if it was already dismissed */
export function showCookieBar({force} = {}) {
    if (force) {
        return createCookieBar();
    }

    let showCookieBar = true;
    try {
        showCookieBar = !localStorage.getItem('cookie-bar-dismissed');
    } catch (e) {}

    if (!showCookieBar && localStorage.getItem('cookie-permissions')) {
        $('body').removeClass('has-cookie-bar');
        let existingServices = Object.keys(JSON.parse(localStorage.getItem('cookie-permissions')));
        showCookieBar = !!(Object.keys(servicePermissions).filter(function (currentService) {
            return !existingServices.includes(currentService)
        }).length);
    }

    if (showCookieBar) {
        return createCookieBar();
    }
}

export function hideCookieBar() {
    if ($cookieBar && $cookieBar.length) {
        $cookieBar.attr('hidden', 'hidden');
        $('body').removeClass('has-cookie-bar');
    }

    try {
        localStorage.setItem('cookie-bar-dismissed', true);
    } catch (e) {}
}

function getCookie(cname) {
    let name = cname + "=";
    let ca = document.cookie.split(';');
    for(let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') {
            c = c.substring(1);
        }
        if (c.indexOf(name) === 0) {
            return c.substring(name.length, c.length);
        }
    }
    return "";
}

function hasCookie(cname) {
    return document.cookie.indexOf(cname) >= 0;
}

let openCallbacks = [];
export function onModalShow(cb) {
    openCallbacks.push(cb);
}
function notifyModalShowCallbacks () {
    openCallbacks.forEach(function (cb) {
        cb(permissionModal);
    });
}