import $ from 'jquery';

export function init () {
    let mainNavItems = $('.navbar .navbar-nav>li'),
        mainNavLinks = $('.navbar .navbar-nav>li>a');

    if (matchMedia('(min-width: 768px)').matches) {
        mainNavLinks.on('touchend', function (evt) {
            if (window.TouchEvent && evt.originalEvent instanceof TouchEvent) {

                let parent = $(this).parent();

                if (!parent.hasClass('is-open')) {
                    evt.preventDefault();
                    evt.stopImmediatePropagation();
                    mainNavItems.removeClass('is-open');
                    parent.addClass('is-open');

                    $('body').on('click', bodyClickHandler);
                }
            }
        });
        mainNavLinks.on('keydown', function(evt) {
            if(evt.keyCode == 40 || evt.keyCode == 38 || evt.keyCode == 32){
                evt.preventDefault();
                $(this).parent().addClass('is-open');
                $('body').on('keyup', escKeyHandler);
            } else if (evt.keyCode == 9 && !$(this).parent().hasClass('is-open')) {
                mainNavItems.removeClass('is-open');
                $('body').off('keyup', escKeyHandler);
            }
        });
    }

    $('.js-toggle-nav-level').on('click', function (evt) {
        evt.preventDefault();
        let  navItem = $(this).closest('li');

        if(!navItem.hasClass('is-open')) {
            navItem.addClass('is-open');
            $('body').on('click', bodyClickHandler);
        } else {
            navItem.removeClass('is-open');
            $('body').off('click', bodyClickHandler);
        }
    });

    $('.js-toggle-subnav').on('click', function (evt){
        evt.preventDefault();
        let navItem = $(this).closest('li');

        if(!navItem.hasClass('is-open')) {
            navItem.addClass('is-open');
        } else {
            navItem.removeClass('is-open');
        }
    });

    function escKeyHandler (evt:any){
        if(evt.keyCode == 27){
            mainNavItems.removeClass('is-open');
            $('body').off('keyup', escKeyHandler);
        }
    }

    function bodyClickHandler (evt:any) {
        if ($(evt.target).parents('.navbar-main').length < 1) {
            evt.preventDefault();
            $('.navbar-main').find('li').removeClass('is-open');
            $('body').off('click', bodyClickHandler);
        }
    }
}