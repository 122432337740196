import {addComponentInitializer} from "./components";
import $ from 'jquery';

function scrollTo ($target:JQuery, callback:any) {
    if (!$target || !$target.length) {
        console.warn("Can't scroll to '" + $target + "'. Target element not found");
        return;
    }

    let duration:number = Math.abs($target.offset().top - window.scrollY) / 8 + 100;
    let offset:number = $target.offset().top - 98;

    $('html, body').stop().animate({
            scrollTop: offset
        },
        duration,
        'swing', function () {
            if (callback) {
                callback();
            }
        }
    );
}

function focus ($target:JQuery) {
    if (typeof $target.attr('tabindex') == 'undefined') {
        $target.addClass('hide-focus');
        $target.attr('tabindex', '-1');
    }
    $target.focus();
}

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        $scope.find('.js-scroll-to').on('click', function (evt) {
            evt.preventDefault();
            let targetSelector: string = $(this).data('scroll-target') || $(this).attr('href');
            let $target = $(targetSelector);


            switch ($(this).data('toggle')) {
                case 'tab':
                    if ($target.hasClass('in')) {
                        scrollTo($target, function () {
                            focus($target);
                        });
                        evt.stopPropagation();
                        return;
                    }

                    /*scroll to parent because we can't know where the actual child is*/
                    scrollTo($target.parent(), function () {
                        focus($target);
                    });

                    /*update bootstrap nav classes because bootstrap doesn't handle them*/
                    let navTabTrigger = $('.nav [data-toggle="tab"]').filter('[href="' + targetSelector + '"], [data-target="' + targetSelector + '"]');
                    navTabTrigger.each(function () {
                        let $navItems = $(this).closest('.nav').find('li');

                        $navItems.removeClass('active')
                            .find('a').attr('aria-expanded', 'false');

                        $(this).attr('aria-expanded', 'true')
                            .parent().addClass('active');

                    });
                    break;
                case 'collapse':
                    if ($target.hasClass('in')) {
                        scrollTo($target, function () {
                            focus($target);
                        });
                        evt.stopImmediatePropagation();
                        return;
                    }

                    /*scroll to parent because we can't know where the actual child is*/
                    scrollTo($target.parent(), function () {
                        focus($target);
                    });

                    break;
                default:
                    scrollTo($target, function () {
                        focus($target);
                    });
            }
        });
    });
}