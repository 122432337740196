import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        let urlChange = $scope.find('.js-change-url');

        urlChange.on('change', function (evt) {
           let url:string = $(this).val();

           $(this).closest('.download-item').find('.js-change-url-btn').attr('href', url);
        });
    })
}