import {addComponentInitializer} from "./components";
import $ from 'jquery';

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        let $tooltip = $scope.find('.js-tooltip');

        $tooltip.tooltip({
            html: true,
            template: '<div class="tooltip" role="tooltip"><span class="tooltip__arrow"></span><div class="tooltip-inner"></div></div>'
        });
    });
}