import $ from 'jquery';
import {addComponentInitializer} from "./components";

export function init () {
    addComponentInitializer(function ($scope:JQuery) {
        let $forms = $scope.find('.js-parsley');

        loadParsley().then(function () {
            $forms.parsley(options);

            $forms.each(function () {
                let $form = $(this);

                $form.parsley().on('field:error', function() {
                    let parsleyId:string = this.__id__;
                    let fieldClass:string = this.__class__;

                    if (fieldClass === 'ParsleyFieldMultiple') {
                        parsleyId = 'multiple-' + this.$element.data('parsley-multiple');
                    }

                    this.$element.attr({
                        'aria-invalid': "true",
                        'aria-describedby': 'parsley-id-'+ parsleyId
                    });
                });

                $form.parsley().on('field:success', function() {
                    this.$element.removeAttr('aria-invalid aria-describedby');
                });
            });
        });
    });
}

export const options = {
    successClass: 'has-success',
    errorClass: 'has-error',
    classHandler: function (_el:any) {
        return _el.$element.closest('.form-group, .js-form-group');
    },
    errorsContainer: function (_el:any) {
        let errorContainer = _el.$element.closest('.form-group').find('.form-errors, .js-form-errors');
        if (errorContainer && errorContainer.length > 0) {
            return errorContainer;
        }
    },
    // nur felder validieren die sichtbar sind
    excluded: "input[type=button], input[type=submit], input[type=reset], input[type=hidden], [disabled], :hidden"
};

let promise:any;
export function loadParsley() {
    if (promise) {
        return promise;
    }

    promise = new Promise(function (resolve, reject) {
        import('parsleyjs').then(function () {
            if (_config.lang && _config.lang !== 'en') {
                import(/* webpackChunkName: "parsley-lang-package-" */'parsleyjs/dist/i18n/' + _config.lang + '.js').then(function () {
                    resolve();
                }).catch(function (reason) {
                    /*fallback if there is no package*/
                    resolve();
                });
            } else {
                resolve();
            }
        }).catch(function (reason) {
            reject();
        });
    });

    return promise;
}

export function isParsleyForm ($form:any) {
    return $form.is('.js-parsley');
}

export function isValid($form:any) {
    if (!$form.data('Parsley')) {
        /* if the form is not initialized */
        $form.parsley(options);
    }

    return $form.data('Parsley').validate();
}