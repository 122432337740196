import $ from 'jquery';

let didScroll: boolean;
let lastScrollTop: number = 0;
let delta: number = 1;
let navbarHeight: any;
let defaultTopPosition: any;
let $navbar: any;
let $navbarContainer: any;
let isTransitioning: boolean = false;
let isFixed: boolean  = false;
let isDisabled: boolean  = false;

export function init () {
    $navbar = $('.js-affix-nav-bar');
    $navbarContainer = $('.js-affix-nav-bar-container');
    defaultTopPosition = $navbarContainer.offset().top;

    if(matchMedia('(min-width: 768px)').matches) {
        $(window).one('scroll', function () {
            navbarHeight = $navbar[0].getBoundingClientRect().height; /*get exact height (jquery returns rounded value)*/
            $navbarContainer.height(navbarHeight);
        });

        $(window).scroll(function (event) {
            didScroll = true;
        });

        requestAnimationFrame(function animationFrameHandler() {

            if (didScroll) {
                scrollHandler();
                didScroll = false;
            }
            requestAnimationFrame(animationFrameHandler);
        });
    }
}

function scrollHandler() {
    let scrollTopPosition = $(window).scrollTop() || 0;

    if (Math.abs(lastScrollTop - scrollTopPosition) <= delta)
        return;

    if (isDisabled) {
        lastScrollTop = scrollTopPosition;
        return;
    }

    let navbarOffset = $navbar.offset().top;

    if (scrollTopPosition <= defaultTopPosition) {
        $navbar.removeClass('is-affix').css('top', 0).removeClass('is-transitioning');
        isFixed = false;
        isTransitioning = true;
        return;
    }

    if (lastScrollTop > scrollTopPosition) {
        /*scroll up*/

        if (!isFixed) {
            if (isTransitioning) {
                if (navbarOffset >= scrollTopPosition) {
                    /*end of transition*/
                    isTransitioning = false;
                    isFixed = true;
                    $navbar.css('top', 0);
                    $navbar.addClass('is-affix');
                    $navbar.removeClass('is-transitioning');
                }
            } else {
                $navbar.addClass('is-transitioning').css({
                    top: scrollTopPosition - navbarHeight
                });
                isTransitioning = true;
            }
        }
    } else if (lastScrollTop < scrollTopPosition) {
        /*scroll down*/

        if (isFixed) {
            $navbar.removeClass('is-affix');

            $navbar.css({
                top: lastScrollTop
            }).addClass('is-transitioning');

            isTransitioning = true;
            isFixed = false;
        }

        if (isTransitioning) {
            if (navbarOffset + navbarHeight <= scrollTopPosition) {
                isTransitioning = false;
            }
        }
    }

    lastScrollTop = scrollTopPosition;
}


function disable(disableAtAffixState:boolean) {
    if (disableAtAffixState) {
        $navbar.addClass('is-affix').css('top', 0).removeClass('is-transitioning');
        isFixed = true;
        isTransitioning = false;
    } else {
        $navbar.removeClass('is-affix').css('top', 0).removeClass('is-transitioning');
        isFixed = false;
        isTransitioning = false;
    }

    isDisabled = true;
}


function enable( ) {
    isDisabled = false;
}

function getAffixNavBarHeight( ) {
    return $('.js-affix-nav-bar').height() || 0;
}

function isInDefaultState( ) {
    return !$navbar.hasClass('is-transitioning') && !$navbar.hasClass('is-affix');
}