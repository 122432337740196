import $ from 'jquery';

export function init () {
    $('.js-toggle-nav').on('click', function (){
        let navbar = $(this).closest('.navbar');

        if(!navbar.hasClass('is-open')) {
            navbar.addClass('is-open');
            $('html').addClass('has-overflow-hidden');
        } else {
            navbar.removeClass('is-open');
            $('html').removeClass('has-overflow-hidden');
        }
    });
}